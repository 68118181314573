import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/catalogue",
    name: "catalogue",
    component: () => import("../views/CatalogueView.vue"),
  },
  {
    path: "/:turban",
    name: "turban-vr",
    children: [
      {
        path: "",
        name: "turban-vr-home",
        component: () => import("../views/VirtualView.vue"),
      },
      {
        path: "desc",
        name: "turban-desc",
        component: () => import("../views/DescriptionView.vue"),
      },
    ],
  },
  {
    path: "/:turban-ar",
    name: "turban-ar",
    component: () => import("../views/ARView.vue"),
  },
  {
    path: "/community",
    component: () => import("../views/CommunityView.vue"),
    name: "community",
    children: [
      {
        path: "forum",
        name: "forum",
        component: () => import("../views/ForumView.vue"),
      },
      {
        path: "contribute",
        name: "contribute",
        component: () => import("../views/ContributeView.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
