<script>
export default {
  name: "HomeView",
};
</script>

<template>
  <div class="flex flex-col gap-6 p-8 min-h-screen container">
    <section class="flex self-center">
      <img src="@/assets/logo.png" alt="logo" class="flex-grow-0" />
    </section>
    <section>
      <h1 class="accent font-bold text-xl my-2">Welcome to Turban Culture</h1>
      <h2>
        From the Prime Minister to the common man, turbans have been a symbol of
        pride, dignity and identity for all Indians. Each turban style
        represents a unique story about the life, culture and history of the
        people that have adopted it. Explore an AR/VR experience to get a
        close-up view of turbans, and even try them on virtually.
      </h2>
    </section>

    <section class="grid grid-cols-2 gap-6 accent">
      <router-link
        to="/catalogue"
        class="flex flex-col btn-tint p-4 rounded-2xl"
      >
        <h1 class="font-bold my-2">AR Catalogue</h1>
        <h2>Explore Turbans with 360° view and AR support</h2>
      </router-link>

      <a
        href="https://halovr.masterchiefs.org"
        class="flex flex-col btn-tint p-4 rounded-2xl"
      >
        <h1 class="font-bold my-2">VR Experience</h1>
        <h2>Experience a virtual museum of Turbans</h2>
      </a>

      <router-link
        to="/community/forum"
        class="flex flex-col btn-tint p-4 rounded-2xl"
      >
        <h1 class="font-bold my-2">Community</h1>
        <h2>
          Upload your own turban's 3D scans and interact with our community
        </h2>
      </router-link>

      <router-link to="/about" class="flex flex-col btn-tint p-4 rounded-2xl">
        <h1 class="font-bold my-2">About us</h1>
        <h2>Get to know the team</h2>
        <img src="@/assets/logo.svg" class="w-8 self-center m-4" />
      </router-link>
    </section>
  </div>
</template>

<style></style>
